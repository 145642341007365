<template>
  <div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="设备注册代码" prop="devicenum">
              <a-input v-model.trim="queryParams.devicenum" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="是否可用" prop="isenable">
              <a-select v-model="queryParams.isenable">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option value="1">可用</a-select-option>
                <a-select-option value="2">不可用</a-select-option>
              </a-select>
            </a-form-model-item>
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="版本号" prop="version">
              <a-input v-model.trim="queryParams.version" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="电梯救援码" prop="liftrescue">
              <a-input v-model.trim="queryParams.liftrescue" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="电梯注册码" prop="liftnum">
              <a-input v-model.trim="queryParams.liftnum" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="电梯地址" prop="adress">
              <a-input v-model.trim="queryParams.adress" placeholder="请输入"></a-input>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{tableTitle}}</h3>
          <div class="table-btns">
<!--            <a-button type="primary" @click="showModal('add')"><a-icon type="plus"></a-icon>新建</a-button>-->
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="devicemoduleinfoid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="isenable" slot-scope="value">
            <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'可用':'不可用'}}</a-tag>
          </span>
          <span slot="updatetime" slot-scope="value">
            <span>{{value?moment(value, 'YYYYMMDDhhmmss').format('YYYY-MM-DD hh:mm:ss'):''}}</span>
          </span>
          <span slot="isonline" slot-scope="value">
            <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'在线':'离线'}}</a-tag>
          </span>
          <span slot="operation" slot-scope="value, record">
            <!-- <a-button type="link" size="small" @click="showModal('detail', value, record)">详情</a-button>
            <a-button type="link" size="small" @click="showModal('edit', value, record)">修改</a-button>
            <a-button type="link" size="small" @click="deleteConfirm(value, record)">删除</a-button> -->
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.devicemoduleinfoid">详情</a-menu-item>
                <a-menu-item :key="'edit-'+record.devicemoduleinfoid">修改</a-menu-item>
<!--                <a-menu-item :key="'delete-'+record.devicemoduleinfoid">删除</a-menu-item>-->
                <a-menu-item :key="'params-'+record.devicemoduleinfoid" >基础参数配置</a-menu-item>
<!--                :disabled="record.isonline!='1'"-->
                <a-menu-item :key="'update-'+record.devicemoduleinfoid" >版本升级</a-menu-item>
<!--                :disabled="record.isonline!='1'"-->
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData" :devicemoduletype="devicemoduletype"></add-or-edit-modal>
    <params-config :visible.sync="paramsModalVisible" @get-operation-result="getTableData" :devicenum="devicenum" :devicemoduletype="devicemoduletype" :modelnum="modelnum"></params-config>
    <update-version :visible.sync="updateModalVisible" @get-operation-result="getTableData" :devicenum="devicenum" :devicemoduletype="devicemoduletype" :modelnum="modelnum"></update-version>
  </div>
</template>
<script>
import moment from 'moment'
import devicemoduletypes from '@/json/devicemoduletypes'
import pagination from '@/mixins/pagination'
import { getItemFromArrayByKey } from 'U'
import { getDevicemoduleinfoListByCondition, deleteDevicemoduleinfo } from 'A/ai.js'
import addOrEditModal from './addOrEditModal'
import ParamsConfig from './ParamsConfig'
import UpdateVersion from './UpdateVersion'
export default {
  mixins: [pagination],
  components: {
    addOrEditModal,
    ParamsConfig,
    UpdateVersion,
  },
  props: {
    devicemoduletype: {
      default: '1'
    }
  },
  data() {
    return {
      showAdvanced: false,
      moment,
      queryParams: {
        devicemoduletype: this.devicemoduletype,
        devicenum: '',
        isenable: '',
        version: '',
        liftrescue: '',
        liftnum: '',
        adress: '',
      },
      counts:'',
      tableColumns: [
        {
          title:'设备名称',
          dataIndex:'devicename',
          key:'devicename',
          ellipsis: true,

        },
        {
          title: '设备注册代码',
          dataIndex: 'devicenum',
          key: 'devicenum',
          ellipsis: true,
          width: 180,
        },
        {
          title:'分机号',
          dataIndex: 'ipnetnum',
          key:'ipnetnum',
          width: 80,
          ellipsis: true,
        },
        // {
        //   title: '是否可用',
        //   dataIndex: 'isenable',
        //   key: 'isenable',
        //   ellipsis: true,
        //   width: 80,
        //   scopedSlots: { customRender: 'isenable' }
        // },
        {
          title: '版本号',
          dataIndex: 'version',
          key: 'version',
          ellipsis: true,
          width: 200,
        },
        // {
        //   title: '更新时间',
        //   dataIndex: 'updatetime',
        //   key: 'updatetime',
        //   ellipsis: true,
        //   scopedSlots: { customRender: 'updatetime' }
        // },
        {
          title: '在线状态',
          dataIndex: 'isonline',
          key: 'isonline',
          ellipsis: true,
          width: 80,
          scopedSlots: { customRender: 'isonline' }
        },
        // {
        //   title: '电梯救援码',
        //   dataIndex: 'liftrescue',
        //   key: 'liftrescue',
        //   ellipsis: true,
        //   width: 80,
        // },
        {
          title: '电梯注册码',
          dataIndex: 'liftnum',
          key: 'liftnum',
          ellipsis: true,
          width: 180,
        },
        {
          title: '电梯地址',
          dataIndex: 'adress',
          key: 'adress',
          ellipsis: true,
        },
        {
          title: '操作',
          key: 'operation',
          width: 80,
          align: 'center',
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      paramsModalVisible: false,
      updateModalVisible: false,
      devicenum: '',
      modelnum: '',
    }
  },
  created() {
    this.getTableData();
  },
  computed: {
    tableTitle() {
      let moduleName = '设备';
      devicemoduletypes.forEach(item => {
        if(item.value == this.devicemoduletype) {
          moduleName = item.text;
        }
      })
      return moduleName + '列表';
    }
  },
  methods: {
    resetQueryParams() {
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      // if(params.adress==''&&params.liftrescue==''&&params.liftnum==''&&params.version==''&&params.isenable==''&&params.devicenum==''){
      //   getDevicemoduleinfoListByCondition(params).then(res => {
      //     this.tableLoading = false;
      //     if(res && res.returncode == '0') {
      //       this.tableData = res.item;
      //       this.counts = res.count;
      //     }
      //   }).catch(err => {
      //     this.tableLoading = false;
      //   })
      // }
      getDevicemoduleinfoListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    deleteConfirm(value, record) {
      this.$confirm({
        // title: '',
        content: '确定要删除该数据?',
        okText: '确定',
        cancelText: '取消',
        onOk:() => {
          this.delete(record.devicemoduleinfoid);
        },
        onCancel() {},
      });
    },
    delete(devicemoduleinfoid) {
      this.showLoading();
      if(devicemoduleinfoid) {
        let params = {
          devicemoduleinfoid
        };
        deleteDevicemoduleinfo(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    showModal(type, value, record) {
      this.modalShowType = type;
      if(type == 'add') {
        this.modalDetailData = null;
      }else {
        this.modalDetailData = record;
      }
      this.modalVisible = true;
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let devicemoduleinfoid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'devicemoduleinfoid', devicemoduleinfoid);
      if(type == 'detail') {
        this.showModal('detail', '', record)
      }else if(type == 'edit') {
        this.showModal('edit', '', record)
      }else if(type == 'delete') {
        this.deleteConfirm('', record);
      }else if(type == 'params') {
        this.devicenum = record.devicenum;
        this.modelnum = record.modelnum;
        // this.paramsModalVisible = true;
        this.$router.push({
          name: 'paramsConfig',
          params: {
            prePages: [
              {
                name: 'AI+物联网监测',
                path: ''
              },
              {
                name: '监测设备管理',
                path: ''
              },
              {
                name: '设备列表',
                path: 'enquipmentList'
              },
            ],
            devicemoduletype: this.devicemoduletype,
            devicenum: record.devicenum,
            modelnum: record.modelnum,
            liftid:record.liftid,
            paramFlag:{
              mqttFlag:0,
              iotFlag:0,
              videoAiFlag:0,
              linphoneFlag:0,
              displayFlag:0,
              selfTestFlag:0,
            }
          }
        })
      }else if(type == 'update') {
        this.devicenum = record.devicenum;
        this.modelnum = record.modelnum;
        this.updateModalVisible = true;
      }
    },
  }
}
</script>