<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :width="660" :centered="true">
    <template slot="footer">
      <a-button v-if="showType=='add'||showType=='edit'" @click="modalVisible=false">取消</a-button>
      <a-button type="primary" @click="confirm">确认</a-button>
    </template>
    <a-form-model ref="modalForm" :model="formDatas" :rules="formRules" :label-col="{span:7}" :wrapper-col="{span:14}">
      <a-form-model-item label="设备类型">
        <a-select :value="devicemoduletype" :disabled="true">
          <a-select-option v-for="(item, index) in devicemoduletypes" :key="index" :value="item.value">{{item.text}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="设备编号" prop="devicenum">
        <a-input v-model.trim="formDatas.devicenum" :disabled="showType=='detail'" />
      </a-form-model-item>
      <a-form-model-item v-if="this.devicemoduletype=='1'" label="监控点ID" prop="cameraIndexcode">
        <a-input v-model.trim="formDatas.cameraIndexcode" :disabled="showType=='detail'" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import devicemoduletypes from '@/json/devicemoduletypes'
import { addDevicemoduleinfo, modifyDevicemoduleInfo } from 'A/ai.js'
export default {
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    },
    devicemoduletype: {
      default: '1'
    },
  },
  data() {
    return {
      devicemoduletypes,
      modalVisible: false,
      formDatas: {
        devicemoduleinfoid: '',
        devicemoduletype: this.devicemoduletype,
        devicenum: '',
        cameraIndexcode: '',
      },
      formRules: {
        devicenum: [{required: true, message: '请输入设备编号'}],
        cameraIndexcode: this.devicemoduletype=='1'?[{required: true, message: '请输入监控点ID'}]:[],
      }
    }
  },
  computed: {
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    }
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    initDetail() {
      if(this.detailData && (this.showType == 'edit' || this.showType == 'detail')) {
        this.$nextTick(() => {
          for(let key in this.formDatas) {
            this.formDatas[key] = this.detailData[key]||'';
          }
        })
      }
    },
    resetDatas() {
      this.formDatas.liftpropertyid = '';
      this.$refs.modalForm.resetFields();
    },
    confirm() {
      // success fail
      if(this.showType == 'add' || this.showType == 'edit') {
        this.$refs.modalForm.validate(valid => {
          if(valid) {
            this.showLoading();
            let params = {
              ...this.formDatas
            };
            if(this.showType == 'add') {
              addDevicemoduleinfo(params).then(res => {
                this.hideLoading();
                if(res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                }else {
                  this.$message.error(res.errormsg||'操作失败');
                }
              })
            }else {
              modifyDevicemoduleInfo(params).then(res => {
                this.hideLoading();
                if(res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                }else {
                  this.$message.error(res.errormsg||'操作失败');
                }
              })
            }
          }else {
            return false;
          }
        })
      }else {
        this.modalVisible = false;
      }
    }
  }
}
</script>