<template>
  <div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="设备注册代码" prop="devicenum">
              <a-input v-model.trim="queryParams.devicenum" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="是否可用" prop="isenable">
              <a-select v-model="queryParams.isenable">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option value="1">可用</a-select-option>
                <a-select-option value="2">不可用</a-select-option>
              </a-select>
            </a-form-model-item>
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="mqtt版本号" prop="mqttversion">
              <a-select v-model="queryParams.mqttversion">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option v-for="(item, index) in mqttversionLists" :key="index" :value="item.mqttversion">{{item.mqttversion}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="基础音视频版本号" prop="videoversion">
              <a-select v-model="queryParams.videoversion">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option v-for="(item, index) in videoversionLists" :key="index" :value="item.videoversion">{{item.videoversion}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="自检版本号" prop="selftestversion">
              <a-select v-model="queryParams.selftestversion">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option v-for="(item, index) in selftestversionLists" :key="index" :value="item.selftestversion">{{item.selftestversion}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="物联网版本号" prop="iotversion">
              <a-select v-model="queryParams.iotversion">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option v-for="(item, index) in iotversionLists" :key="index" :value="item.iotversion">{{item.iotversion}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="AI版本号" prop="aiversion">
              <a-select v-model="queryParams.aiversion">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option v-for="(item, index) in aiversionLists" :key="index" :value="item.aiversion">{{item.aiversion}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="通话版本号" prop="linphoneversion">
              <a-select v-model="queryParams.linphoneversion">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option v-for="(item, index) in linphoneversionLists" :key="index" :value="item.linphoneversion">{{item.linphoneversion}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="电梯救援码" prop="liftrescue">
              <a-input v-model.trim="queryParams.liftrescue" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="电梯注册码" prop="liftnum">
              <a-input v-model.trim="queryParams.liftnum" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="电梯地址" prop="adress">
              <a-input v-model.trim="queryParams.adress" placeholder="请输入"></a-input>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{tableTitle}}</h3>
          <div class="table-btns">
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="deviceinfoid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="isenable" slot-scope="value">
            <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'可用':'不可用'}}</a-tag>
          </span>
          <span slot="updatetime" slot-scope="value">
            <span>{{value?moment(value, 'YYYYMMDDhhmmss').format('YYYY-MM-DD hh:mm:ss'):''}}</span>
          </span>
          <span slot="mqtt_isonline" slot-scope="value">
            <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'在线':'离线'}}</a-tag>
          </span>
          <span slot="video_isonline" slot-scope="value">
            <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'在线':'离线'}}</a-tag>
          </span>
          <span slot="selftest_isonline" slot-scope="value">
            <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'在线':'离线'}}</a-tag>
          </span>
          <span slot="iot_isonline" slot-scope="value">
            <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'在线':'离线'}}</a-tag>
          </span>
          <span slot="ai_isonline" slot-scope="value">
            <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'在线':'离线'}}</a-tag>
          </span>
          <span slot="linphone_isonline" slot-scope="value">
            <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'在线':'离线'}}</a-tag>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
<!--                <a-menu-item :key="'detail-'+record.deviceinfoid">详情</a-menu-item>-->
<!--                <a-menu-item :key="'edit-'+record.deviceinfoid">修改</a-menu-item>-->
                <a-menu-item :key="'params-'+record.deviceinfoid" >基础参数配置</a-menu-item>
                <a-menu-item :key="'update-'+record.deviceinfoid" >版本升级</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-quick-jumper show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <update-version :visible.sync="updateModalVisible" @get-operation-result="getDeviceVersionList" :devicenum="devicenum" :modelnum="modelnum"></update-version>
  </div>
</template>
<script>
import moment from 'moment'
import devicemoduletypes from '@/json/devicemoduletypes'
import pagination from '@/mixins/pagination'
import { getItemFromArrayByKey } from 'U'
import {getDeviceinfoVersionListByCondition, deleteDevicemoduleinfo, getDeviceVersionMap} from 'A/ai.js'
import UpdateVersion from "V/ai/monitoringEquipment/enquipmentList/aiCameraList/UpdateVersion";
export default {
  mixins: [pagination],
  components: {
    UpdateVersion
  },
  props: {
    devicemoduletype: {
      default: '1'
    }
  },
  data() {
    return {
      showAdvanced: false,
      moment,
      queryParams: {
        devicemoduletype: this.devicemoduletype,
        devicenum: '',
        isenable: '',
        version: '',
        liftrescue: '',
        liftnum: '',
        adress: '',
        mqttversion:'',
        videoversion:'',
        selftestversion:'',
        iotversion:'',
        aiversion:'',
        linphoneversion:'',
      },
      mqttversionLists:[],
      videoversionLists:[],
      selftestversionLists:[],
      iotversionLists:[],
      aiversionLists:[],
      linphoneversionLists:[],
      counts:'',
      tableColumns: [
        {
          title:'设备名称',
          dataIndex:'devicename',
          key:'devicename',
          width: 240,
          ellipsis: true,
        },
        {
          title: '设备注册代码',
          dataIndex: 'devicenum',
          key: 'devicenum',
          ellipsis: true,
          width: 165,
        },
        {
          title: '状态',
          dataIndex: 'mqtt_isonline',
          key: 'mqtt_isonline',
          ellipsis: true,
          width: 45,
          scopedSlots: { customRender: 'mqtt_isonline' }
        },
        {
          title: 'mqtt模块版本号',
          dataIndex: 'mqttversion',
          key: 'mqttversion',
          ellipsis: true,
        },
        {
          title: '状态',
          dataIndex: 'video_isonline',
          key: 'video_isonline',
          ellipsis: true,
          width: 45,
          scopedSlots: { customRender: 'video_isonline' }
        },
        {
          title: '基础音视频模块版本号',
          dataIndex: 'videoversion',
          key: 'videoversion',
          ellipsis: true,
        },
        {
          title: '状态',
          dataIndex: 'selftest_isonline',
          key: 'selftest_isonline',
          ellipsis: true,
          width: 45,
          scopedSlots: { customRender: 'selftest_isonline' }
        },
        {
          title: '自检模块版本号',
          dataIndex: 'selftestversion',
          key: 'selftestversion',
          ellipsis: true,
        },
        {
          title: '状态',
          dataIndex: 'iot_isonline',
          key: 'iot_isonline',
          ellipsis: true,
          width: 45,
          scopedSlots: { customRender: 'iot_isonline' }
        },
        {
          title: '物联网模块版本号',
          dataIndex: 'iotversion',
          key: 'iotversion',
          ellipsis: true,
        },
        {
          title: '状态',
          dataIndex: 'ai_isonline',
          key: 'ai_isonline',
          ellipsis: true,
          width: 45,
          scopedSlots: { customRender: 'ai_isonline' }
        },
        {
          title: 'AI模块版本号',
          dataIndex: 'aiversion',
          key: 'aiversion',
          ellipsis: true,
        },
        {
          title: '状态',
          dataIndex: 'linphone_isonline',
          key: 'linphone_isonline',
          ellipsis: true,
          width: 45,
          scopedSlots: { customRender: 'linphone_isonline' }
        },
        {
          title: '通话模块版本号',
          dataIndex: 'linphoneversion',
          key: 'linphoneversion',
          ellipsis: true,
        },
        {
          title: '操作',
          key: 'operation',
          width: 40,
          align: 'center',
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      paramsModalVisible: false,
      updateModalVisible: false,
      devicenum: '',
      modelnum: '',
    }
  },
  created() {
    this.getTableData();
    this.getDeviceVersionList();
  },
  computed: {
    tableTitle() {
      // let moduleName = '设备';
      // devicemoduletypes.forEach(item => {
      //   if(item.value == this.devicemoduletype) {
      //     moduleName = item.text;
      //   }
      // })
      // return moduleName + '列表';
      return '设备版本号列表';
    }
  },
  methods: {
    resetQueryParams() {
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getDeviceVersionList(){
      getDeviceVersionMap({}).then(res=>{
        if(res&&res.returncode=='0'){
          console.log(res)
          this.mqttversionLists=res.mqtt.filter(item=>{
            if(item==null){
            }else{
              return item.mqttversion
            }
          })
          this.videoversionLists=res.video.filter(item=>{
            if(item==null){
            }else{
              return item.videoversion
            }
          })
          this.selftestversionLists=res.selftest.filter(item=>{
            if(item==null){
            }else{
              return item.selftestversion
            }
          })
          this.iotversionLists=res.iot.filter(item=>{
            if(item==null){
            }else{
              return item.iotversion
            }
          })
          this.aiversionLists=res.ai.filter(item=>{
            if(item==null){
            }else{
              return item.aiversion
            }
          })
          this.linphoneversionLists=res.linphone.filter(item=>{
            if(item==null){
            }else{
              return item.linphoneversion
            }
          })
        }
      })
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getDeviceinfoVersionListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    deleteConfirm(value, record) {
      this.$confirm({
        // title: '',
        content: '确定要删除该数据?',
        okText: '确定',
        cancelText: '取消',
        onOk:() => {
          this.delete(record.devicemoduleinfoid);
        },
        onCancel() {},
      });
    },
    delete(devicemoduleinfoid) {
      this.showLoading();
      if(devicemoduleinfoid) {
        let params = {
          devicemoduleinfoid
        };
        deleteDevicemoduleinfo(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    showModal(type, value, record) {
      this.modalShowType = type;
      if(type == 'add') {
        this.modalDetailData = null;
      }else {
        this.modalDetailData = record;
      }
      this.modalVisible = true;
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let deviceinfoid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'deviceinfoid', deviceinfoid);
      if(type == 'detail') {
        this.showModal('detail', '', record)
      }else if(type == 'edit') {
        this.showModal('edit', '', record)
      }else if(type == 'delete') {
        this.deleteConfirm('', record);
      }else if(type == 'params') {
        this.devicenum = record.devicenum;
        this.modelnum = record.modelnum;
        // this.paramsModalVisible = true;
        console.log(record)
        this.$router.push({
          name: 'paramsConfig',
          params: {
            prePages: [
              {
                name: 'AI+物联网监测',
                path: ''
              },
              {
                name: '监测设备管理',
                path: ''
              },
              {
                name: '设备列表',
                path: 'enquipmentList'
              },
            ],
            // devicemoduletype: this.devicemoduletype,
            devicenum: record.devicenum,
            modelnum: record.modelnum,
            liftid:record.liftid,
            paramFlag:{
              mqttFlag:0,
              iotFlag:0,
              videoAiFlag:0,
              linphoneFlag:0,
              displayFlag:0,
              selfTestFlag:0,
              videoParamFlag:0,
            }
          }
        })
      }else if(type == 'update') {
        this.devicenum = record.devicenum;
        this.modelnum = record.modelnum;
        this.updateModalVisible = true;
      }
    },
  }
}
</script>