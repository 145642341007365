<template>
  <ai-camera-list devicemoduletype="4"></ai-camera-list>
</template>
<script>
import aiCameraList from '../aiCameraList/Index'
export default {
  components: {
    aiCameraList
  },
}
</script>